
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'d': 'M108.28 45.61L1.46997 230.61H215.09L108.28 45.61ZM108.28 90.14L176.53 208.35H40L108.28 90.14Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.25', 'd': 'M215.09 230.62L146.83 112.39L133.98 134.65L176.53 208.35L215.09 230.62Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.25', 'd': 'M108.28 45.61L133.98 90.12L121.12 112.38L108.28 90.14V45.61Z'}),
createElement('path', {'d': 'M163.63 186H78.62L133.94 90.2L185.5 1L236.9 90.2L292.25 186H189.34L176.48 163.74H253.68L211.2 90.2L185.5 45.5L159.64 90.2L117.18 163.74H150.78L163.63 186Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.6', 'd': 'M253.68 163.74H176.48L189.34 186H292.25L253.68 163.74Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.6', 'd': 'M117.18 163.74H150.78L163.63 186H78.62L117.18 163.74Z'}),
createElement('path', {'d': 'M185.5 45.53L211.2 90.2H236.9L185.5 1L133.94 90.2H159.64L185.5 45.53Z'}),
createElement('defs', {})
), 'LogoWithoutText')
    