import React from 'react'
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { mergeDeepLeft } from 'ramda'

import defaultTheme from './defaultTheme'
import useContent from './hooks/useContent'

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      html: {
        fontSize: '14pt',
        [theme.breakpoints.down('md')]: {
          fontSize: '13pt',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12pt',
        },
        overflowY: 'scroll',
      },
      video: {
        backgroundColor: 'transparent !important',
      },
    },
  }),
  { name: 'PeakStateBaseline' },
)

const HELBaseline = () => {
  useStyles({})
  return <></>
}

const ThemeProvider: React.FC = (props) => {
  const { children } = props
  const { theme: clientTheme } = useContent()
  const mergedTheme = createMuiTheme(mergeDeepLeft(clientTheme, defaultTheme) as ThemeOptions)

  return (
    <MuiThemeProvider theme={mergedTheme}>
      <CssBaseline />
      <HELBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
