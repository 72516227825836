import React from 'react'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Link as RouterLink } from 'react-router-dom'
import { useHover } from 'react-use'

import useContent from '../hooks/useContent'

import Markdown from '../components/Markdown'

import { PeaksGraphic } from '../svg'
import { CategoryMarkdownCopy } from '*/copy.yaml'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    paper: {
      width: '100%',
      height: 130,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
      position: 'relative',
      zIndex: 0,
    },
    mountain: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: 0,
      zIndex: 0,
    },
    text: {
      backgroundColor: fade(theme.palette.common.white, 0.75),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2),
      position: 'relative',
      zIndex: 1,
    },
  }),
)

const CategoryBox: React.FC<{ id: string; content: CategoryMarkdownCopy }> = (props) => {
  const { id, content } = props
  const { svg } = useContent()
  const classes = useStyles()

  const peaksGraphic = svg.PeaksGraphic || PeaksGraphic

  const element = (hovered: boolean) => (
    <Paper square className={classes.paper}>
      <img className={classes.mountain} width={125} src={peaksGraphic} alt={content.title} />
      <Box className={classes.text}>
        <Markdown content={content.title} variantMap={{ p: 'h4' }} />
        <Markdown content={content.summary ?? ''} variantMap={{ p: 'body1' }} />
      </Box>
    </Paper>
  )
  const [hoverable] = useHover((a) => element(a))

  return (
    <ButtonBase focusRipple className={classes.root} to={`/${id}`} component={RouterLink}>
      {hoverable}
    </ButtonBase>
  )
}

export default CategoryBox
