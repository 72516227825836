
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('g', {'clipPath': 'url(#clip0)'}),
createElement('defs', {})
), 'LogoTextOnly')
    