import React from 'react'
import clsx from 'clsx'
import unified from 'unified'
import markdown from 'remark-parse'
import addAttributes from 'remark-attr'
import remark2rehype from 'remark-rehype'
import rehype2react, { ComponentProps } from 'rehype-react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Variant } from '@material-ui/core/styles/createTypography'
import Link, { LinkProps } from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

import useContent from '../hooks/useContent'

import VideoPlayer from '../components/VideoPlayer'
import AudioPlayer from '../components/AudioPlayer'

const useStyles = makeStyles((theme) =>
  createStyles({
    clearfix: {
      overflow: 'auto',
      '&::after': {
        content: '""',
        clear: 'both',
        display: 'table',
      },
    },
    imageContainer: {
      float: 'left',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    image: {
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(1),
    },
    caption: {
      textAlign: 'center',
    },
  }),
)

interface Props {
  className?: string
  content: string
  variantMap?: {
    h1?: Variant
    h2?: Variant
    h3?: Variant
    h4?: Variant
    h5?: Variant
    h6?: Variant
    p?: Variant
  }
  linkProps?: LinkProps
  typographyProps?: TypographyProps
  variantTypographyProps?: {
    [variant in Variant]?: TypographyProps
  }
  paragraphs?: boolean
}

const Markdown: React.FC<Props> = (props) => {
  const classes = useStyles({})
  const {
    className,
    content,
    variantMap,
    paragraphs,
    typographyProps,
    variantTypographyProps,
    linkProps,
  } = props
  const { audio, images, buildMediaUrl } = useContent()
  const resolvedParagraphs = paragraphs === undefined ? false : paragraphs

  // bodgy... if we have pictures, we need to clearfix, otherwiser, clearfix causes issues so don't apply it
  let hasImages = false

  const h1: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h1 || 'h1' : 'h1'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h2: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h2 || 'h2' : 'h2'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h3: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h3 || 'h3' : 'h3'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h4: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h4 || 'h4' : 'h4'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h5: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h5 || 'h5' : 'h5'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const h6: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.h6 || 'h6' : 'h6'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const p: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography
        paragraph={resolvedParagraphs}
        variant={variant}
        {...typographyProps}
        {...(variantTypographyProps || {})[variant]}
      >
        {children}
      </Typography>
    )
  }
  const ul: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography paragraph={resolvedParagraphs} variant={variant} component="ul">
        {children}
      </Typography>
    )
  }
  const ol: React.FC<ComponentProps> = ({ children }) => {
    const variant = variantMap !== undefined ? variantMap.p || 'body1' : 'body1'
    return (
      <Typography paragraph={resolvedParagraphs} variant={variant} component="ol">
        {children}
      </Typography>
    )
  }
  const a: React.FC<ComponentProps> = ({ children, href }) => {
    if ((href as string).startsWith('audio://')) {
      return (
        <AudioPlayer src={audio[(href as string).replace('audio://', '') as keyof typeof audio]}>
          {(onClick) => (
            <Link onClick={onClick} {...linkProps}>
              {children}
            </Link>
          )}
        </AudioPlayer>
      )
    }
    if ((href as string).startsWith('video://')) {
      return (
        <VideoPlayer src={buildMediaUrl('video', (href as string).replace('video://', ''))}>
          {(onClick) => (
            <Link onClick={onClick} {...linkProps}>
              {children}
            </Link>
          )}
        </VideoPlayer>
      )
    }
    if ((href as string).startsWith('/')) {
      return (
        <Link component={RouterLink} to={href as string} {...linkProps}>
          {children}
        </Link>
      )
    }
    return (
      <Link href={href as string} {...linkProps}>
        {children}
      </Link>
    )
  }
  const img: React.FC<ComponentProps> = ({ src, alt }) => {
    let resolvedSrc = src as string
    if (resolvedSrc.startsWith('image://')) {
      resolvedSrc = images[resolvedSrc.replace('image://', '') as keyof typeof images]
    }
    hasImages = true
    return (
      <div className={classes.imageContainer}>
        <img className={classes.image} src={resolvedSrc} alt={alt as string} />
        <Typography className={classes.caption} paragraph={resolvedParagraphs} variant={'caption'}>
          {alt as string}
        </Typography>
      </div>
    )
  }

  var parser = unified().use(markdown).use(addAttributes).use(remark2rehype)
  var processor = parser.use(rehype2react, {
    createElement: React.createElement,
    components: {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a,
      ul,
      ol,
      img,
    },
  })

  const renderedContent = processor.processSync(content).result as React.ReactNode

  return <Box className={clsx(hasImages && classes.clearfix, className)}>{renderedContent}</Box>
}

export default Markdown
