import * as React from 'react'
import { mergeDeepLeft } from 'ramda'

import { ContentContext } from '../ContentContextProvider'

import * as fortem from '../clients/fortem'
import * as breathe from '../clients/breathe'
import * as breatheEng from '../clients/breathe-eng'
import { Content } from '../types'

const content = { fortem, breathe, 'breathe-eng': breatheEng }

type mediaTypes = 'video' // |'audio'|'image'|'vtt'
export const buildMediaUrl = (realm: string, cdnVersion: string) => (
  type: mediaTypes,
  name: string,
  resource: string = '',
) => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    switch (type) {
      case 'video':
        return `http://localhost:8000/${name}.mp4`
      default:
        throw new Error('Not implemented')
    }
  }

  switch (type) {
    case 'video':
      const domain = process.env.REACT_APP_MEDIA_DOMAIN
      const path = `${domain}/${realm}/${cdnVersion}/${resource}/videos/${name}/hls.m3u8`.replaceAll(
        '//',
        '/',
      )
      return `https://${path}`
    default:
      throw new Error('Not implemented')
  }
}

const useContent = () => {
  const context = React.useContext(ContentContext)
  const clientContent = content[(context || '') as keyof typeof content]

  const {
    markdownCopy,
    markdownContent,
    mediaContent,
    configuration: { cdnVersion },
  } = clientContent

  return {
    ...clientContent,
    content: mergeDeepLeft(mergeDeepLeft(markdownCopy, markdownContent), mediaContent) as Content,
    buildMediaUrl: context ? buildMediaUrl(context, cdnVersion) : () => '',
  }
}

export default useContent
