import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink } from 'react-router-dom'

import useContent from '../hooks/useContent'

import Markdown from '../components/Markdown'

import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    support: {
      paddingBottom: theme.spacing(1),
    },
    links: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: theme.spacing(1, 0),
    },
    link: {
      margin: theme.spacing(0, 1),
    },
  }),
)

interface Props {
  className?: string
  contentStructure: ContentStructure
  content: Content
}

const Footer: React.FC<Props> = (props) => {
  const { className, contentStructure, content } = props
  const { images } = useContent()
  const classes = useStyles()

  return (
    <Container role="contentinfo" className={clsx(className, classes.root)}>
      <Markdown paragraphs content={content.footer.supportedByTitle} variantMap={{ p: 'h6' }} />
      <Box className={classes.support}>
        {content.footer.supportedBy.map((s) => {
          if (s.url) {
            return (
              <Link className={classes.link} key={s.image as string} href={s.url} target="_blank">
                <img height={60} src={images[s.image as keyof typeof images]} alt={s.alt} />
              </Link>
            )
          } else {
            return (
              <img
                key={s.image as string}
                className={classes.link}
                height={60}
                src={images[s.image as keyof typeof images]}
                alt={s.alt}
              />
            )
          }
        })}
      </Box>
      <Box className={classes.links}>
        {contentStructure.legals.map((legalId) => (
          <Link
            color="inherit"
            className={classes.link}
            key={legalId}
            to={`/legal/${legalId}`}
            component={RouterLink}
          >
            <Typography variant="caption">{content.legals[legalId].title}</Typography>
          </Link>
        ))}
      </Box>
      <Markdown content={content.footer.copyright} variantMap={{ p: 'caption' }} />
    </Container>
  )
}

export default Footer
