import React, { CSSProperties } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { TransitionGroup, Transition } from 'react-transition-group'
import Box from '@material-ui/core/Box'

interface AnimationProps {
  fade?: boolean
  slide?: boolean
}

const useAnimatedStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.enteringScreen,
      }),
      transitionProperty: 'opacity, transform',
      opacity: 0,
      backgroundColor: theme.palette.background.default,
    },
  }),
)

interface AnimatedOnProps extends AnimationProps {
  state: string
}

const AnimatedOn: React.FC<AnimatedOnProps> = ({ children, state, fade, slide }) => {
  const classes = useAnimatedStyles()

  const transitionStyles: { [key: string]: CSSProperties } = {
    entering: {
      opacity: fade ? 0 : 1,
      transform: slide ? 'translate(0px, 10px)' : undefined,
    },
    entered: {
      opacity: 1,
    },
    exiting: {
      transition: 'none',
      opacity: 0,
    },
    exited: {
      opacity: 0,
    },
  }

  return (
    <Box className={classes.root} style={transitionStyles[state as keyof typeof transitionStyles]}>
      {children}
    </Box>
  )
}

const useStyles = makeStyles<Theme, { backgroundColor: string }>((theme) =>
  createStyles({
    root: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
  }),
)

interface AnimateOnProps extends AnimationProps {
  on?: string
  backgroundColor: string
}

const AnimateOn: React.FC<AnimateOnProps> = (props) => {
  const { on, backgroundColor, children, ...animations } = props
  const classes = useStyles({ backgroundColor })
  const theme = useTheme()
  const nodeRef = React.useRef<HTMLDivElement>(null)

  return (
    <TransitionGroup>
      <Transition
        key={on}
        nodeRef={nodeRef}
        appear={true}
        timeout={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
      >
        {(state) => (
          <div ref={nodeRef as React.RefObject<HTMLDivElement>} className={classes.root}>
            <AnimatedOn {...animations} state={state}>
              {children}
            </AnimatedOn>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

export default AnimateOn
