import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

const titleFontFamily = "'Open Sans', sans-serif"
const fontFamily = "'Open Sans', sans-serif"

const options: ThemeOptions = {
  typography: {
    fontFamily,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: titleFontFamily,
    },
    h2: {
      fontFamily: titleFontFamily,
    },
    h3: {
      fontFamily: titleFontFamily,
    },
    h4: {
      fontFamily: titleFontFamily,
    },
    h5: {
      fontFamily: titleFontFamily,
    },
    h6: {
      fontFamily: titleFontFamily,
    },
    body1: {
      fontFamily,
    },
    body2: {
      fontFamily,
      fontWeight: 600,
    },
    button: {
      fontFamily: titleFontFamily,
    },
    caption: {
      fontFamily,
    },
    subtitle1: {
      fontFamily,
    },
  },
}

export default options
