
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'d': 'M19 21V17.6562L18.9944 17.6525V6.34754L19 6.34382V3L5.49442 12L19 21Z'})
), 'ArrowLeft')
    