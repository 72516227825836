import * as React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import useContent from '../hooks/useContent'

interface Props {
  setContent: React.Dispatch<React.SetStateAction<string | undefined>>
}

const LanguageSelector: React.FC<Props> = (props) => {
  const { setContent } = props
  const { configuration } = useContent()

  // initialise analytics once - with initial content config
  const defaultLanguageInitialised = React.useRef(false)
  React.useEffect(() => {
    // do not initialise default language when we are creating the static pages and only do it once
    if (!navigator.userAgent.match(/Node\.js/i) && !defaultLanguageInitialised.current) {
      defaultLanguageInitialised.current = true

      const preferredLanguages = navigator.languages.map((c) => c.split('-')[0].toLocaleLowerCase())
      console.log(preferredLanguages)

      const preferredLanguageConfiguration = configuration.availableLanguages?.find((l) =>
        preferredLanguages.includes(l.code),
      )
      preferredLanguageConfiguration && setContent(preferredLanguageConfiguration?.content)
    }
  }, [configuration, setContent])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (contentIdentifier: string) => () => {
    setContent(contentIdentifier)
    setAnchorEl(null)
  }

  return configuration.availableLanguages && configuration.language ? (
    <div>
      <Button
        color="inherit"
        aria-controls="language-selection-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {configuration.language.toUpperCase()}
      </Button>
      <Menu
        id="language-selection-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {configuration.availableLanguages.map((l) => (
          <MenuItem onClick={handleClose(l.content)}>{l.code.toUpperCase()}</MenuItem>
        ))}
      </Menu>
    </div>
  ) : null
}

export default LanguageSelector
