import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import clsx from 'clsx'
import { useCookie, useSize } from 'react-use'
import { Link as RouterLink } from 'react-router-dom'

import useWidth from '../hooks/useWidth'
import Markdown from './Markdown'

const useStyles = makeStyles((theme) =>
  createStyles({
    fix: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
    root: {
      backgroundColor: theme.palette.secondary.main,
      overflow: 'hidden',
      maxHeight: 1000,
    },
    dismissed: {
      transition: theme.transitions.create('max-height', {
        duration: theme.transitions.duration.leavingScreen,
      }),
      maxHeight: 0,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.common.white,
      padding: theme.spacing(1),
    },
    text: {
      margin: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    actions: {
      flexShrink: 0,
    },
  }),
)

interface Props {
  message: string
  hideMessage: string
  readMore: {
    title: string
    to: string
  }
}

const CookieWarning: React.FC<Props> = (props) => {
  const { message, hideMessage, readMore } = props
  const classes = useStyles()
  const [value, updateCookie] = useCookie('cookie-checkbox-necessary')
  const width = useWidth()

  const dismissed = value === 'false'

  const textVariant = width === 'xs' ? 'caption' : 'body1'

  const [sized, { height }] = useSize(() => (
    <Box className={clsx(classes.root, { [classes.dismissed]: dismissed })}>
      <Container className={classes.container}>
        <Markdown
          className={classes.text}
          content={message}
          variantMap={{ h1: 'h6', p: textVariant }}
        />
        <Box className={classes.actions}>
          <Button variant="contained" color="primary" onClick={() => updateCookie('false')}>
            {hideMessage}
          </Button>
          <Link className={classes.text} color="inherit" component={RouterLink} to={readMore.to}>
            {readMore.title}
          </Link>
        </Box>
      </Container>
    </Box>
  ))

  if (dismissed) {
    return null
  }

  return (
    <>
      <Box style={{ width, height }} />
      <Box className={classes.fix}>{sized}</Box>
    </>
  )
}

export default CookieWarning
