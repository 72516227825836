import { SimplePaletteColorOptions } from '@material-ui/core'
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

const primary: SimplePaletteColorOptions = {
  light: '#00abe9',
  main: '#00abe9',
  dark: '#00abe9',
}

const secondary: SimplePaletteColorOptions = {
  light: '#003043',
  main: '#003043',
  dark: '#003043',
}

const error: SimplePaletteColorOptions = {
  light: '#FDEFED',
  main: '#FF7155',
}

const success: SimplePaletteColorOptions = {
  light: '#E9FFDB',
  main: '#C0FF99',
  dark: '#6CD22D',
}

const palette = {
  primary,
  secondary,
  error,
  success,
  common: {
    black: '#000',
    white: '#fff',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#1A1A1A',
    secondary: '#FFF',
    disabled: '#D6D6D6',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: '#333',
  background: {
    default: '#FFFFFF',
    paper: '#e6f2f1',
  },
  action: {
    active: '#757575',
    hover: '#F2F2F2',
    hoverOpacity: 0.08,
    selected: '#EAEAFD',
    disabled: '#D6D6D6',
    disabledBackground: '#EAEAEA',
  },
}

const titleFontFamily = '"Radikal-Bold", "Roboto", "Helvetica", "Arial", sans-serif'
const fontFamily = '"century-gothic", "Roboto", "Helvetica", "Arial", sans-serif'

const typography = {
  fontFamily,
  htmlFontSize: 13,
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '3.75rem',
    lineHeight: 1.0,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  h2: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1.0,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  h3: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.2,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  h4: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: 1.2,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  h5: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.2,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  h6: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '0.9rem',
    lineHeight: 1.6,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  body1: {
    fontFamily,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0em',
  },
  body2: {
    fontFamily,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0em',
  },
  button: {
    fontFamily: titleFontFamily,
    fontWeight: 700,
    fontSize: '0.975rem',
    lineHeight: 1.75,
    letterSpacing: '0em',
    textTransform: 'uppercase' as 'uppercase',
  },
  caption: {
    fontFamily,
    fontWeight: 400,
    fontSize: '0.8rem',
    lineHeight: 1.66,
    letterSpacing: '0em',
  },
  subtitle1: {
    fontFamily,
    fontWeight: 700,
    fontSize: '1.2rem',
    lineHeight: 1.2,
    letterSpacing: '0em',
  },
}

const spacing = 12

const options: ThemeOptions = {
  palette,
  typography,
  spacing,
  direction: 'ltr',
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 1050,
      lg: 1280,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 200,
      shorter: 250,
      short: 400,
      standard: 700,
      complex: 1200,
      enteringScreen: 800,
      leavingScreen: 500,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  props: {
    MuiLink: {
      underline: 'always',
      color: 'secondary',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: spacing * 0.75,
      },
    },
    MuiButton: {
      text: {
        padding: spacing * 0.75,
      },
    },
    MuiDivider: {
      root: {
        height: 0.5,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
  },
}

export default options
