import ThePowerOfNature from './ThePowerOfNature.vtt'
import Intro from './Intro.vtt'
import PositiveSelfTalk from './PositiveSelfTalk.vtt'
import StrengtheningOurSocialSupportNetwork from './StrengtheningOurSocialSupportNetwork.vtt'

export { default as ThePowerOfNature } from './ThePowerOfNature.vtt'
export { default as Intro } from './Intro.vtt'
export { default as PositiveSelfTalk } from './PositiveSelfTalk.vtt'
export { default as StrengtheningOurSocialSupportNetwork } from './StrengtheningOurSocialSupportNetwork.vtt'

export default {
  ThePowerOfNature,
  Intro,
  PositiveSelfTalk,
  StrengtheningOurSocialSupportNetwork,
}
