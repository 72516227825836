import React from 'react'
import { render } from 'react-snapshot'

import ContentContextProvider from './ContentContextProvider'
import App from './App'
import { unregister as unregisterServiceWorker } from './serviceWorker'

render(
  <React.StrictMode>
    <ContentContextProvider>
      {(setContent) => <App setContent={setContent} />}
    </ContentContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker()
