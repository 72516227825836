
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'d': 'M3 9.29371H4.83673V14.7063H3V9.29371Z'}),
createElement('path', {'d': 'M5.69388 6.58741H7.53061V17.4126H5.69388V6.58741Z'}),
createElement('path', {'d': 'M8.38775 3H10.2245V21H8.38775V3Z'}),
createElement('path', {'d': 'M11.0816 6.58741H12.9184V17.4126H11.0816V6.58741Z'}),
createElement('path', {'d': 'M13.7755 9.29371H15.6122V14.7063H13.7755V9.29371Z'}),
createElement('path', {'d': 'M16.4694 6.58741H18.3061V17.4126H16.4694V6.58741Z'}),
createElement('path', {'d': 'M19.1633 3H21V21H19.1633V3Z'})
), 'Soundwave')
    