import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

import useWidth from '../hooks/useWidth'
import VideoViewer from '../components/VideoViewer'
import Markdown from '../components/Markdown'
import Strikethrough from '../components/Strikethrough'
import CategoryBox from '../components/CategoryBox'
import CategoryBoxMobile from '../components/CategoryBoxMobile'

import useContent from '../hooks/useContent'

import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

import { LogoWithoutText } from '../svg'

const targetSmallBoxSize = 190
const targetMediumBoxSize = 235
const targetLargestBoxSize = 290

const useStyles = makeStyles<
  Theme,
  { bannerImage?: string; introImage?: string; mentalFitnessImage?: string }
>((theme) =>
  createStyles({
    bannerBlock: {
      background: theme.palette.common.white,
      backgroundImage: ({ bannerImage }) => `url(${bannerImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      backgroundPosition: 'center',
    },
    darkBlock: {
      padding: theme.spacing(6, 0),
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    tintedBlock: {
      padding: theme.spacing(6, 0),
      background: theme.palette.background.paper,
      color: theme.palette.secondary.main,
    },
    banner: {
      [theme.breakpoints.down('xs')]: {
        height: 250,
      },
      [theme.breakpoints.up('sm')]: {
        height: 540,
      },
      [theme.breakpoints.up('lg')]: {
        height: 640,
      },
      color: theme.palette.common.white,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    categoryBoxes: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: -targetMediumBoxSize / 4,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: -targetLargestBoxSize / 4,
      },
      position: 'relative',
    },
    categoryBoxBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      [theme.breakpoints.down('xs')]: {
        marginTop: targetSmallBoxSize / 4,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: targetMediumBoxSize / 4,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: targetLargestBoxSize / 4,
      },
      backgroundColor: theme.palette.background.paper,
    },
    categoryBoxBackground2: {
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        height: targetSmallBoxSize / 4,
      },
      [theme.breakpoints.up('sm')]: {
        height: targetMediumBoxSize / 4,
      },
      [theme.breakpoints.up('lg')]: {
        height: targetLargestBoxSize / 4,
      },
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.secondary.main,
    },
    categoryBoxesGrid: {
      display: 'grid',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
      [theme.breakpoints.up('xs')]: {
        gridTemplateColumns: `repeat(2, ${targetMediumBoxSize}px)`,
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(4, 1fr)`,
      },
      gap: `${theme.spacing(2)}px`,
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      zIndex: 1,
      padding: theme.spacing(2),
      overflow: 'hidden',
    },
    categoryBoxesSecondGrid: {
      marginTop: theme.spacing(2),
    },
    homeSubTitle: {
      marginBottom: theme.spacing(3),
    },
    whatContainer: {
      maxWidth: 'fit-content',
      width: 'max-content',
      margin: '0 auto',
    },
    whatRow: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    whatLogo: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    whatBody: {
      maxWidth: 500,
      flex: 1,
    },
    howContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    howTitle: {
      textAlign: 'center',
    },
    howGrid: {
      maxWidth: 1000,
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: `repeat(1, 1fr)`,
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(2, 1fr)`,
      },
      alignItems: 'center',
      gridColumnGap: theme.spacing(1),
      gridRowGap: theme.spacing(1),
    },
    introVideo: {
      width: '100%',
      backgroundImage: ({ introImage }) => `url(${introImage})`,
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 300,
      },
      [theme.breakpoints.up('sm')]: {
        height: 450,
      },
      [theme.breakpoints.up('md')]: {
        height: 600,
      },
      [theme.breakpoints.up('lg')]: {
        height: 745,
      },
      color: theme.palette.common.white,
    },
    mentalFitnessVideo: {
      width: '100%',
      backgroundImage: ({ mentalFitnessImage }) => `url(${mentalFitnessImage})`,
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 300,
      },
      [theme.breakpoints.up('sm')]: {
        height: 450,
      },
      [theme.breakpoints.up('md')]: {
        height: 600,
      },
      [theme.breakpoints.up('lg')]: {
        height: 745,
      },
      color: theme.palette.common.white,
    },
  }),
)

interface Props {
  contentStructure: ContentStructure
  content: Content
}

const Home: React.FC<Props> = (props) => {
  const {
    contentStructure: { categories: categoryIds },
    content,
  } = props
  const { svg, images, buildMediaUrl } = useContent()
  const logoWithoutText = svg.LogoWithoutText || LogoWithoutText
  const classes = useStyles({
    bannerImage: images[content.home.banner.image as keyof typeof images],
    introImage: images[content.home.intro.image as keyof typeof images],
    mentalFitnessImage: images[content.home.mentalFitness.image as keyof typeof images],
  })
  const width = useWidth()

  const bannerTitleVariant = width === 'xs' ? 'h5' : width === 'sm' ? 'h3' : 'h2'

  const titleVariant = width === 'xs' ? 'h5' : width === 'sm' ? 'h4' : 'h3'
  const howTitleVariant = width === 'xs' ? 'h3' : width === 'sm' ? 'h2' : 'h1'

  const categoryBoxes =
    width === 'xl' || width === 'lg' || width === 'md' ? (
      <Container>
        <Paper className={classes.categoryBoxesGrid} elevation={6}>
          {categoryIds.map((_categoryId) => (
            <CategoryBox
              key={_categoryId}
              id={_categoryId}
              content={content.categories[_categoryId]}
              achieve={content.general.achieve}
            />
          ))}
        </Paper>
      </Container>
    ) : width === 'sm' ? (
      <Container>
        <Paper className={classes.categoryBoxesGrid} elevation={6}>
          {categoryIds.slice(0, 2).map((_categoryId) => (
            <CategoryBox
              key={_categoryId}
              id={_categoryId}
              content={content.categories[_categoryId]}
              achieve={content.general.achieve}
            />
          ))}
        </Paper>
        <Paper
          className={clsx(classes.categoryBoxesGrid, classes.categoryBoxesSecondGrid)}
          elevation={6}
        >
          {categoryIds.slice(2, 4).map((_categoryId) => (
            <CategoryBox
              key={_categoryId}
              id={_categoryId}
              content={content.categories[_categoryId]}
              achieve={content.general.achieve}
            />
          ))}
        </Paper>
      </Container>
    ) : (
      categoryIds.map((_categoryId) => (
        <CategoryBoxMobile
          key={_categoryId}
          id={_categoryId}
          content={content.categories[_categoryId]}
        />
      ))
    )

  return (
    <>
      <Box className={classes.bannerBlock}>
        <Container className={classes.banner}>
          <Markdown content={content.home.tagline} variantMap={{ p: bannerTitleVariant }} />
        </Container>
      </Box>
      <Box className={classes.categoryBoxes}>
        <Box className={classes.categoryBoxBackground} />
        <Box className={classes.categoryBoxBackground2} />
        {categoryBoxes}
      </Box>
      <Box className={classes.darkBlock}>
        <Container>
          <Box className={classes.whatContainer}>
            {width === 'xs' && (
              <img
                className={classes.whatLogo}
                width={125}
                src={logoWithoutText}
                alt={content.name}
              />
            )}
            <Markdown
              className={classes.homeSubTitle}
              content={content.home.what.title}
              variantMap={{ p: titleVariant }}
            />
            <Box className={classes.whatRow}>
              {width !== 'xs' && (
                <img
                  className={classes.whatLogo}
                  width={125}
                  src={logoWithoutText}
                  alt={content.name}
                />
              )}
              <Markdown paragraphs className={classes.whatBody} content={content.home.what.body} />
            </Box>
          </Box>
        </Container>
      </Box>
      <VideoViewer
        className={classes.introVideo}
        src={buildMediaUrl('video', content.home.intro.video.src)}
        vtt={content.home.intro.video.vtt}
      >
        <Typography paragraph variant={titleVariant}>
          {content.home.intro.title}
        </Typography>
        <Typography paragraph variant="subtitle1">
          {content.home.intro.subtitle}
        </Typography>
      </VideoViewer>
      <Box className={classes.tintedBlock}>
        <Container className={classes.howContainer}>
          <Markdown
            className={clsx(classes.homeSubTitle, classes.howTitle)}
            content={content.home.how.title}
            variantMap={{ p: titleVariant }}
          />
          <Box className={classes.howGrid}>
            <Strikethrough>
              <Markdown content={content.general.stop} variantMap={{ p: howTitleVariant }} />
            </Strikethrough>
            <Markdown content={content.home.how.stop} />
            <Strikethrough>
              <Markdown content={content.general.challenge} variantMap={{ p: howTitleVariant }} />
            </Strikethrough>
            <Markdown content={content.home.how.challenge} />
            <Strikethrough>
              <Markdown content={content.general.change} variantMap={{ p: howTitleVariant }} />
            </Strikethrough>
            <Markdown content={content.home.how.change} />
          </Box>
        </Container>
      </Box>
      {content.home.mentalFitness && (
        <VideoViewer
          className={classes.mentalFitnessVideo}
          src={buildMediaUrl('video', content.home.mentalFitness.video.src)}
          vtt={content.home.mentalFitness.video.vtt}
        >
          <Typography paragraph variant={titleVariant}>
            {content.home.mentalFitness.title}
          </Typography>
          <Typography paragraph variant="subtitle1">
            {content.home.mentalFitness.subtitle}
          </Typography>
        </VideoViewer>
      )}
    </>
  )
}

export default Home
