import React from 'react'
import MuiModal, { ModalProps as MuiModalProps } from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { useTheme } from '@material-ui/core/styles'

const Modal: React.FC<MuiModalProps> = (props) => {
  const { open, children } = props
  const theme = useTheme()

  return (
    <>
      <MuiModal
        {...props}
        BackdropComponent={Backdrop}
        BackdropProps={{
          transitionDuration: {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
          },
        }}
      >
        <Fade
          in={open}
          timeout={{
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
          }}
        >
          {children}
        </Fade>
      </MuiModal>
    </>
  )
}

export default Modal
