import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

import useOnScreen from '../hooks/useOnScreen'

const useStyles = makeStyles<Theme, { colour?: string }>((theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingLeft: theme.spacing(0.5),
      marginLeft: -theme.spacing(0.5),
      zIndex: 0,
    },
    streak: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(0%, -50%)',
      height: '20%',
      width: 0,
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen * 2,
        delay: theme.transitions.duration.short,
      }),
      backgroundColor: ({ colour }) => colour || theme.palette.common.white,
      zIndex: -1,
    },
    appeared: {
      width: '100%',
    },
  }),
)

interface Props {
  className?: string
  colour?: string
}

const Strikethrough: React.FC<Props> = (props) => {
  const { className, colour, children } = props
  const classes = useStyles({ colour })
  const { setRef, isOnScreen } = useOnScreen<HTMLDivElement>(1)

  // track whether ever on screen
  const [appeared, setAppeared] = React.useState(false)
  React.useEffect(() => setAppeared((_appeared) => _appeared || isOnScreen), [isOnScreen])

  return (
    <div ref={setRef} className={clsx(className, classes.root)}>
      <div className={clsx(classes.streak, { [classes.appeared]: appeared })} />
      {children}
    </div>
  )
}

export default Strikethrough
