
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'d': 'M5.4944 21V17.6562L5.49999 17.6525V6.34754L5.4944 6.34382V3L19 12L5.4944 21Z'})
), 'ArrowRight')
    