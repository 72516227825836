import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'
import { ToolContent } from '../types'
import { Link as RouterLink } from 'react-router-dom'

import useWidth from '../hooks/useWidth'
import { ArrowRightIcon } from '../icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
  }),
)

interface Props {
  className?: string
  categoryId: string
  toolIds: string[]
  toolContents: ToolContent[]
}

const ToolList: React.FC<Props> = (props) => {
  const { className, categoryId, toolIds, toolContents } = props
  const classes = useStyles()
  const width = useWidth()

  const variant = width === 'xs' ? 'h6' : width === 'sm' ? 'h5' : 'h5'

  return (
    <Box className={clsx(className, classes.root)}>
      <List>
        {toolIds.map((toolId, i) => (
          <ListItem
            disableGutters={width === 'xs'}
            button
            key={toolId}
            to={`/${categoryId}/${toolId}`}
            component={RouterLink}
          >
            <ListItemText
              primaryTypographyProps={{ variant: variant }}
              primary={toolContents[i].title}
            />
            <ArrowRightIcon className={classes.icon} color="primary" fontSize="small" />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ToolList
