// import Analytics from 'analytics'
// import googleAnalytics from '@analytics/google-analytics'
import { Configuration } from '*/configuration.yaml'
import firebaseConfig from './firebase-config.json'

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

export default (configuration: Configuration) => {
  window.dataLayer = window.dataLayer || []

  function gtag(method: 'js', date: Date): void
  function gtag(method: 'set', data: { [key: string]: unknown }): void
  function gtag(method: 'config' | 'event', data: string, config?: { [key: string]: unknown }): void
  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }

  gtag('js', new Date())

  gtag('config', firebaseConfig.measurementId, { send_page_view: false })
  configuration.googleAnalyticsCode &&
    gtag('config', configuration.googleAnalyticsCode, { send_page_view: false })

  const pageView = () =>
    setTimeout(() => {
      const page = {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      }
      gtag('event', 'page_view', page)
    }, 500)

  // see https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript/41825103#41825103
  // for why we do this
  var pushState = window.history.pushState
  window.history.pushState = function (...args) {
    pushState.apply(window.history, args)
    pageView()
  }

  pageView()

  // this does not have the same interface as analytics....
  return gtag
}

// currently analytics does not support gtag
// has an active PR: https://github.com/DavidWells/analytics/pull/125#pullrequestreview-550888179
// export default () => {
//   const plugins = [
//     googleAnalytics({
//       trackingId: firebaseConfig.measurementId,
//     }),
//     ...(configuration.googleAnalyticsCode
//       ? [
//           {
//             ...googleAnalytics({
//               trackingId: configuration.googleAnalyticsCode,
//               instanceName: 'client',
//             }),
//             ...{
//               name: 'google-analytics-client',
//             },
//           },
//         ]
//       : []),
//   ]

//   console.log(`connecting to ${configuration.googleAnalyticsCode}`)
//   console.log(plugins)
//   const analytics = Analytics({
//     app: firebaseConfig.projectId,
//     plugins,
//   })

//   const pageView = () =>
//     setTimeout(() => {
//       analytics.page().then(() => console.log(`page send complete`))
//     }, 500)

//   // see https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript/41825103#41825103
//   // for why we do this
//   var pushState = window.history.pushState
//   window.history.pushState = function (...args) {
//     console.log(`sending to ${configuration.googleAnalyticsCode}`)
//     pushState.apply(window.history, args)
//     pageView()
//   }

//   pageView()

//   // Fire function when 'page' calls happen
//   analytics.on('page', ({ payload }) => {
//     console.log(`page send occurred`)
//     console.log(payload)
//   })

//   return analytics
// }
