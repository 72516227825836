
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('style', {'type': 'text/css'}),
createElement('path', {'d': 'M3.3,5.6v0.1l0,12.6v0.3V22l17.3-10L3.3,2L3.3,5.6z M6.6,16.4V7.6l7.7,4.4L6.6,16.4z'})
), 'Arrow3Right')
    