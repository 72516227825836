import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useParams, Redirect } from 'react-router-dom'

import ToolList from '../components/ToolList'
import DocumentTitle from '../components/DocumentTitle'

import { CategoryMarkdownCopy } from '*/copy.yaml'
import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      color: theme.palette.secondary.main,
    },
    divider: {
      margin: theme.spacing(3, -3),
      backgroundColor: theme.palette.secondary.main,
      height: theme.spacing(0.25),
    },
    tools: {
      margin: '0 auto',
    },
  }),
)

interface Props {
  contentStructure: ContentStructure
  content: Content
}

const Category: React.FC<Props> = (props) => {
  const {
    content,
    contentStructure: { tools: toolDefinitions },
  } = props
  const { categoryId } = useParams<{ categoryId: string }>()
  const categoryContent: CategoryMarkdownCopy = content.categories[categoryId]
  const classes = useStyles()

  if (categoryContent === undefined) {
    return <Redirect to="/404" />
  }

  const toolIds = toolDefinitions
    .filter((d) => categoryId === 'tools' || d.categories.includes(categoryId))
    .map((d) => d.id)

  return (
    <>
      <DocumentTitle title={categoryContent.title} subtitle={categoryContent.subtitle} />
      <ToolList
        className={classes.tools}
        categoryId={categoryId}
        toolIds={toolIds}
        toolContents={toolIds.map((toolId) => content.tools[toolId])}
      />
    </>
  )
}

export default Category
