import GroundingTechniques from './GroundingTechniques.png'
import Gratitude from './Gratitude.jpg'
import EmotionalRegulationSystems from './EmotionalRegulationSystems.svg'
import ProblemSolving from './ProblemSolving.jpg'
import GoalSetting from './GoalSetting.jpg'
import SocialImage from './SocialImage.png'
import StrengthsIdentification from './StrengthsIdentification.jpg'
import ThePowerOfNature from './ThePowerOfNature.jpg'
import VanessaGreen from './VanessaGreen.png'
import HomeBanner from './HomeBanner.jpg'
import BodyRelaxationTechniques from './BodyRelaxationTechniques.jpg'
import SelfCompassionAndAcceptance from './SelfCompassionAndAcceptance.jpg'
import HeadsTogether from './HeadsTogether.png'
import Intro from './Intro.jpg'
import StandingTall from './StandingTall.jpg'
import Visualisation from './Visualisation.jpg'
import EverydayCreativity from './EverydayCreativity.jpg'
import BreathingTechniques from './BreathingTechniques.jpg'
import SelfAwarenessOfValues from './SelfAwarenessOfValues.jpg'
import IntroThumbnail from './IntroThumbnail.png'
import EmotionalDiffusionTechniques from './EmotionalDiffusionTechniques.jpg'
import HeaderHome from './HeaderHome.png'
import MinistryOfDefence from './MinistryOfDefence.png'
import StrengtheningOurSocialSupportNetwork from './StrengtheningOurSocialSupportNetwork.jpg'
import FifteenMinuteRule from './FifteenMinuteRule.jpg'
import Fortem from './Fortem.jpg'
import WhatIsMentalFitness from './WhatIsMentalFitness.jpg'
import PositiveSelfTalk from './PositiveSelfTalk.jpg'
import NathanJones from './NathanJones.png'
import DistractionTechniques from './DistractionTechniques.jpg'
import IncreasingPositiveAttitude from './IncreasingPositiveAttitude.jpg'
import CognitiveModel from './CognitiveModel.svg'
import PeakState from './PeakState.svg'
import DavidWiseman from './DavidWiseman.png'
import PositiveSelfAffirmations from './PositiveSelfAffirmations.jpg'

export { default as GroundingTechniques } from './GroundingTechniques.png'
export { default as Gratitude } from './Gratitude.jpg'
export { default as EmotionalRegulationSystems } from './EmotionalRegulationSystems.svg'
export { default as ProblemSolving } from './ProblemSolving.jpg'
export { default as GoalSetting } from './GoalSetting.jpg'
export { default as SocialImage } from './SocialImage.png'
export { default as StrengthsIdentification } from './StrengthsIdentification.jpg'
export { default as ThePowerOfNature } from './ThePowerOfNature.jpg'
export { default as VanessaGreen } from './VanessaGreen.png'
export { default as HomeBanner } from './HomeBanner.jpg'
export { default as BodyRelaxationTechniques } from './BodyRelaxationTechniques.jpg'
export { default as SelfCompassionAndAcceptance } from './SelfCompassionAndAcceptance.jpg'
export { default as HeadsTogether } from './HeadsTogether.png'
export { default as Intro } from './Intro.jpg'
export { default as StandingTall } from './StandingTall.jpg'
export { default as Visualisation } from './Visualisation.jpg'
export { default as EverydayCreativity } from './EverydayCreativity.jpg'
export { default as BreathingTechniques } from './BreathingTechniques.jpg'
export { default as SelfAwarenessOfValues } from './SelfAwarenessOfValues.jpg'
export { default as IntroThumbnail } from './IntroThumbnail.png'
export { default as EmotionalDiffusionTechniques } from './EmotionalDiffusionTechniques.jpg'
export { default as HeaderHome } from './HeaderHome.png'
export { default as MinistryOfDefence } from './MinistryOfDefence.png'
export { default as StrengtheningOurSocialSupportNetwork } from './StrengtheningOurSocialSupportNetwork.jpg'
export { default as FifteenMinuteRule } from './FifteenMinuteRule.jpg'
export { default as Fortem } from './Fortem.jpg'
export { default as WhatIsMentalFitness } from './WhatIsMentalFitness.jpg'
export { default as PositiveSelfTalk } from './PositiveSelfTalk.jpg'
export { default as NathanJones } from './NathanJones.png'
export { default as DistractionTechniques } from './DistractionTechniques.jpg'
export { default as IncreasingPositiveAttitude } from './IncreasingPositiveAttitude.jpg'
export { default as CognitiveModel } from './CognitiveModel.svg'
export { default as PeakState } from './PeakState.svg'
export { default as DavidWiseman } from './DavidWiseman.png'
export { default as PositiveSelfAffirmations } from './PositiveSelfAffirmations.jpg'

export default {
  GroundingTechniques,
  Gratitude,
  EmotionalRegulationSystems,
  ProblemSolving,
  GoalSetting,
  SocialImage,
  StrengthsIdentification,
  ThePowerOfNature,
  VanessaGreen,
  HomeBanner,
  BodyRelaxationTechniques,
  SelfCompassionAndAcceptance,
  HeadsTogether,
  Intro,
  StandingTall,
  Visualisation,
  EverydayCreativity,
  BreathingTechniques,
  SelfAwarenessOfValues,
  IntroThumbnail,
  EmotionalDiffusionTechniques,
  HeaderHome,
  MinistryOfDefence,
  StrengtheningOurSocialSupportNetwork,
  FifteenMinuteRule,
  Fortem,
  WhatIsMentalFitness,
  PositiveSelfTalk,
  NathanJones,
  DistractionTechniques,
  IncreasingPositiveAttitude,
  CognitiveModel,
  PeakState,
  DavidWiseman,
  PositiveSelfAffirmations,
}
