import PositiveSelfTalk from './PositiveSelfTalk.mp3'
import FifteenMinuteRule from './FifteenMinuteRule.mp3'
import EmotionalDiffusionTechniques from './EmotionalDiffusionTechniques.mp3'
import StrengtheningOurSocialSupportNetwork from './StrengtheningOurSocialSupportNetwork.mp3'
import IncreasingPositiveAttitude from './IncreasingPositiveAttitude.mp3'
import DistractionTechniques from './DistractionTechniques.mp3'
import Plus2Activity from './Plus2Activity.mp3'
import BodyRelaxationTechniques from './BodyRelaxationTechniques.mp3'
import SelfCompassionAndAcceptance from './SelfCompassionAndAcceptance.mp3'
import ThePowerOfNature from './ThePowerOfNature.mp3'
import StrengthsIdentification from './StrengthsIdentification.mp3'
import BreathingTechniques from './BreathingTechniques.mp3'
import EverydayCreativity from './EverydayCreativity.mp3'
import Visualisation from './Visualisation.mp3'
import SelfAwarenessOfValues from './SelfAwarenessOfValues.mp3'
import StandingTall from './StandingTall.mp3'
import GuidedBreathingActivity from './GuidedBreathingActivity.mp3'
import ProblemSolving from './ProblemSolving.mp3'
import Gratitude from './Gratitude.mp3'
import GroundingTechniques from './GroundingTechniques.mp3'
import ProgressiveRelaxationActivity from './ProgressiveRelaxationActivity.mp3'
import GoalSetting from './GoalSetting.mp3'

export { default as PositiveSelfTalk } from './PositiveSelfTalk.mp3'
export { default as FifteenMinuteRule } from './FifteenMinuteRule.mp3'
export { default as EmotionalDiffusionTechniques } from './EmotionalDiffusionTechniques.mp3'
export { default as StrengtheningOurSocialSupportNetwork } from './StrengtheningOurSocialSupportNetwork.mp3'
export { default as IncreasingPositiveAttitude } from './IncreasingPositiveAttitude.mp3'
export { default as DistractionTechniques } from './DistractionTechniques.mp3'
export { default as Plus2Activity } from './Plus2Activity.mp3'
export { default as BodyRelaxationTechniques } from './BodyRelaxationTechniques.mp3'
export { default as SelfCompassionAndAcceptance } from './SelfCompassionAndAcceptance.mp3'
export { default as ThePowerOfNature } from './ThePowerOfNature.mp3'
export { default as StrengthsIdentification } from './StrengthsIdentification.mp3'
export { default as BreathingTechniques } from './BreathingTechniques.mp3'
export { default as EverydayCreativity } from './EverydayCreativity.mp3'
export { default as Visualisation } from './Visualisation.mp3'
export { default as SelfAwarenessOfValues } from './SelfAwarenessOfValues.mp3'
export { default as StandingTall } from './StandingTall.mp3'
export { default as GuidedBreathingActivity } from './GuidedBreathingActivity.mp3'
export { default as ProblemSolving } from './ProblemSolving.mp3'
export { default as Gratitude } from './Gratitude.mp3'
export { default as GroundingTechniques } from './GroundingTechniques.mp3'
export { default as ProgressiveRelaxationActivity } from './ProgressiveRelaxationActivity.mp3'
export { default as GoalSetting } from './GoalSetting.mp3'

export default {
  PositiveSelfTalk,
  FifteenMinuteRule,
  EmotionalDiffusionTechniques,
  StrengtheningOurSocialSupportNetwork,
  IncreasingPositiveAttitude,
  DistractionTechniques,
  Plus2Activity,
  BodyRelaxationTechniques,
  SelfCompassionAndAcceptance,
  ThePowerOfNature,
  StrengthsIdentification,
  BreathingTechniques,
  EverydayCreativity,
  Visualisation,
  SelfAwarenessOfValues,
  StandingTall,
  GuidedBreathingActivity,
  ProblemSolving,
  Gratitude,
  GroundingTechniques,
  ProgressiveRelaxationActivity,
  GoalSetting,
}
