
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'d': 'M4 6.24V6.33V17.67V17.94V21L19.57 12L4 3V6.24ZM6.97 15.96V8.04L13.9 12L6.97 15.96Z'})
), 'Play')
    