import SelfAwarenessOfValuesGREEK from './SelfAwarenessOfValuesGREEK.vtt'
import IntroENG from './IntroENG.vtt'
import SelfAwarenessOfValuesENG from './SelfAwarenessOfValuesENG.vtt'
import GroundingTechniquesENG from './GroundingTechniquesENG.vtt'
import StandingTallGREEK from './StandingTallGREEK.vtt'
import PositiveSelfTalkGREEK from './PositiveSelfTalkGREEK.vtt'
import BreathingTechniquesGREEK from './BreathingTechniquesGREEK.vtt'
import GroundingTechniquesGREEK from './GroundingTechniquesGREEK.vtt'
import BreathingTechniquesENG from './BreathingTechniquesENG.vtt'
import GratitudeENG from './GratitudeENG.vtt'
import GratitudeGREEK from './GratitudeGREEK.vtt'
import FullIntroENG from './FullIntroENG.vtt'
import IntroGREEK from './IntroGREEK.vtt'
import FullIntroGREEK from './FullIntroGREEK.vtt'
import DistractionTechniquesGREEK from './DistractionTechniquesGREEK.vtt'
import ThePowerOfNatureGREEK from './ThePowerOfNatureGREEK.vtt'
import DistractionTechniquesENG from './DistractionTechniquesENG.vtt'
import PositiveSelfTalkENG from './PositiveSelfTalkENG.vtt'
import StandingTallENG from './StandingTallENG.vtt'
import ThePowerOfNatureENG from './ThePowerOfNatureENG.vtt'

export { default as SelfAwarenessOfValuesGREEK } from './SelfAwarenessOfValuesGREEK.vtt'
export { default as IntroENG } from './IntroENG.vtt'
export { default as SelfAwarenessOfValuesENG } from './SelfAwarenessOfValuesENG.vtt'
export { default as GroundingTechniquesENG } from './GroundingTechniquesENG.vtt'
export { default as StandingTallGREEK } from './StandingTallGREEK.vtt'
export { default as PositiveSelfTalkGREEK } from './PositiveSelfTalkGREEK.vtt'
export { default as BreathingTechniquesGREEK } from './BreathingTechniquesGREEK.vtt'
export { default as GroundingTechniquesGREEK } from './GroundingTechniquesGREEK.vtt'
export { default as BreathingTechniquesENG } from './BreathingTechniquesENG.vtt'
export { default as GratitudeENG } from './GratitudeENG.vtt'
export { default as GratitudeGREEK } from './GratitudeGREEK.vtt'
export { default as FullIntroENG } from './FullIntroENG.vtt'
export { default as IntroGREEK } from './IntroGREEK.vtt'
export { default as FullIntroGREEK } from './FullIntroGREEK.vtt'
export { default as DistractionTechniquesGREEK } from './DistractionTechniquesGREEK.vtt'
export { default as ThePowerOfNatureGREEK } from './ThePowerOfNatureGREEK.vtt'
export { default as DistractionTechniquesENG } from './DistractionTechniquesENG.vtt'
export { default as PositiveSelfTalkENG } from './PositiveSelfTalkENG.vtt'
export { default as StandingTallENG } from './StandingTallENG.vtt'
export { default as ThePowerOfNatureENG } from './ThePowerOfNatureENG.vtt'

export default {
  SelfAwarenessOfValuesGREEK,
  IntroENG,
  SelfAwarenessOfValuesENG,
  GroundingTechniquesENG,
  StandingTallGREEK,
  PositiveSelfTalkGREEK,
  BreathingTechniquesGREEK,
  GroundingTechniquesGREEK,
  BreathingTechniquesENG,
  GratitudeENG,
  GratitudeGREEK,
  FullIntroENG,
  IntroGREEK,
  FullIntroGREEK,
  DistractionTechniquesGREEK,
  ThePowerOfNatureGREEK,
  DistractionTechniquesENG,
  PositiveSelfTalkENG,
  StandingTallENG,
  ThePowerOfNatureENG,
}
