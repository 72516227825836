import LogoWithText from './LogoWithText.svg'
import LogoWithoutText from './LogoWithoutText.svg'
import LogoTextOnly from './LogoTextOnly.svg'
import PeaksGraphic from './PeaksGraphic.svg'

export { default as LogoWithText } from './LogoWithText.svg'
export { default as LogoWithoutText } from './LogoWithoutText.svg'
export { default as LogoTextOnly } from './LogoTextOnly.svg'
export { default as PeaksGraphic } from './PeaksGraphic.svg'

export default {
  LogoTextOnly,
  LogoWithText,
  LogoWithoutText,
  PeaksGraphic
}
