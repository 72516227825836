import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'

import useWidth from '../hooks/useWidth'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      color: theme.palette.secondary.main,
    },
    divider: {
      margin: theme.spacing(3, -3),
      backgroundColor: theme.palette.secondary.main,
      height: theme.spacing(0.25),
    },
    tools: {
      margin: '0 auto',
    },
  }),
)

interface Props {
  title: string
  subtitle?: string
}

const DocumentTitle: React.FC<Props> = (props) => {
  const { title, subtitle } = props
  const classes = useStyles()
  const width = useWidth()

  const titleVariant = width === 'xs' ? 'h3' : 'h2'

  return (
    <Box className={classes.title}>
      <Typography paragraph variant={titleVariant}>
        {title}
      </Typography>
      {subtitle !== undefined ? (
        <Typography paragraph variant="body1">
          {subtitle}
        </Typography>
      ) : null}
      <Divider className={classes.divider} />
    </Box>
  )
}

export default DocumentTitle
