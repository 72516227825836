
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('path', {'fillRule': 'evenodd', 'clipRule': 'evenodd', 'd': 'M51.2344 100.028L106.676 4L158.766 94.2213L169.955 74.8109L204.653 135H135.258H71.3946H2L36.6973 74.8109L51.2344 100.028Z'}),
createElement('path', {'opacity': '0.5', 'd': 'M106.676 4L31.0434 135H182.309L106.676 4Z'}),
createElement('defs', {})
), 'PeaksGraphic')
    