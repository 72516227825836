import React from 'react'
import { is } from 'ramda'
import Hls from 'hls.js'

import useContent from '../hooks/useContent'

import { VttTrack } from '*/media_content.yaml'

interface Props
  extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  vtt?: VttTrack[]
  bufferLength?: number
}

const Video = React.forwardRef<HTMLVideoElement, Props>((props, forwardedRef) => {
  const { src, autoPlay, vtt, bufferLength, ...otherProps } = props
  const { vtt: vttTracks } = useContent()
  const videoRef = React.useRef<HTMLVideoElement>()

  React.useLayoutEffect(() => {
    if (forwardedRef && is(Function)(forwardedRef) && videoRef.current) {
      ;(forwardedRef as (instance: HTMLVideoElement | null) => void)(videoRef.current)
    } else if (forwardedRef && videoRef.current) {
      ;(forwardedRef as React.MutableRefObject<HTMLVideoElement>).current = videoRef.current
    }
  }, [forwardedRef])

  React.useLayoutEffect(() => {
    if (src?.toLowerCase().endsWith('.mp4')) {
      videoRef.current && (videoRef.current.src = src)
      videoRef.current?.play()
      return
    }

    let cancel = false
    var hls = new Hls({})
    // hls.startLevel = -1
    // hls.loadLevel = 4

    if (videoRef.current && src) {
      if (Hls.isSupported()) {
        hls.loadSource(src)
        hls.attachMedia(videoRef.current)
        if (autoPlay) {
          // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
          hls.on(
            Hls.Events.MEDIA_ATTACHED,
            () => !cancel && videoRef.current && videoRef.current.play(),
          )
        }
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = src
        if (autoPlay) {
          videoRef.current.addEventListener(
            'loadedmetadata',
            () => !cancel && videoRef.current && videoRef.current.play(),
          )
        }
      }
    }

    return () => {
      hls.destroy()
      cancel = true
    }
  }, [src, autoPlay, bufferLength])

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef as React.RefObject<HTMLVideoElement>} {...otherProps}>
      {(vtt || []).map((track, i) => (
        <track {...track} src={vttTracks[track.src as keyof typeof vtt]} default={track.default} />
      ))}
    </video>
  )
})

export default Video
