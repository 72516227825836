import LogoWithText from './LogoWithText.svg'
import LogoTextOnly from './LogoTextOnly.svg'

export { default as LogoWithText } from './LogoWithText.svg'
export { default as LogoTextOnly } from './LogoTextOnly.svg'

export default {
  LogoTextOnly,
  LogoWithText,
} as {
  [key: string]: any | undefined
}
