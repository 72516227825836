import React from 'react'
import { createStyles, makeStyles, fade, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link as RouterLink } from 'react-router-dom'
import { useWindowScroll, useSize } from 'react-use'

import useWidth from '../hooks/useWidth'
import useContent from '../hooks/useContent'

import LanguageSelector from './LanguageSelector'

import { LogoWithText, LogoTextOnly } from '../svg'
import { ArrowLeftIcon, ArrowRightIcon, MenuIcon } from '../icons'
import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

const useListItemStyles = makeStyles((theme) =>
  createStyles({
    button: {
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
)

const NavListItem = ({
  to,
  title,
  withArrow,
}: {
  to: string
  title: string
  withArrow?: boolean
}) => (
  <ListItem classes={useListItemStyles()} button to={to} component={RouterLink}>
    <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={title} />
    {withArrow && <ArrowRightIcon color="primary" fontSize="inherit" />}
  </ListItem>
)

const useStyles = makeStyles<Theme, { opacity: number }>((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      zIndex: 100,
      width: '100%',
    },
    navBar: {
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
      }),
      backgroundColor: ({ opacity }) => fade(theme.palette.primary.main, opacity),
      color: theme.palette.common.white, // ({ opacity }) => lighten(theme.palette.text.primary, 1.0 - opacity),
      width: '100%',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr auto 1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '"left middle right"',
      padding: theme.spacing(0.5),
      alignItems: 'center',
    },
    left: {
      gridArea: 'left',
      justifySelf: 'flex-start',
    },
    middle: {
      gridArea: 'middle',
      justifySelf: 'center',
    },
    right: {
      gridArea: 'right',
      justifySelf: 'flex-end',
      display: 'flex',
    },
    drawer: {
      width: 280,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    drawerMain: {
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    drawerLogo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2, 0),
    },
    drawerLogoImg: {
      height: 'intrinsic',
    },
    drawerTitle: {
      margin: theme.spacing(2, 2),
      textAlign: 'center',
    },
    drawerList: {
      padding: theme.spacing(0, 1),
      width: '100%',
      '& > a:hover': {
        color: theme.palette.text.primary,
      },
    },
    drawerLink: {
      margin: theme.spacing(0.5, 0),
    },
  }),
)

interface Props {
  className?: string
  contentStructure: ContentStructure
  content: Content
  transparent: boolean
  homeLink?: string
  previous?: {
    to: string
    title: string
  }
  setContent: React.Dispatch<React.SetStateAction<string | undefined>>
}

const NavBar: React.FC<Props> = (props) => {
  const {
    className,
    contentStructure,
    content,
    transparent,
    previous,
    homeLink = '/home',
    setContent,
  } = props
  const { svg } = useContent()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const width = useWidth()
  const { y: scroll } = useWindowScroll()
  const classes = useStyles({ opacity: transparent && scroll < 100 ? 0.0 : 1.0 })

  const logoWithText = svg.LogoWithText || LogoWithText
  const logoTextOnly = svg.LogoTextOnly || LogoTextOnly

  const titleVariant = width === 'xs' ? 'h6' : width === 'sm' ? 'h5' : 'h5'

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawerOpen(open)
  }

  const homeButton = React.useMemo(() => {
    if (homeLink.startsWith('/')) {
      return (
        <Button color="inherit" component={RouterLink} to={homeLink}>
          <img height={width !== 'xs' ? 16 : 12} src={logoTextOnly} alt="Peak State" />
        </Button>
      )
    }
    return (
      <Button color="inherit" component={Link} href={homeLink}>
        <img height={width !== 'xs' ? 16 : 12} src={logoTextOnly} alt="Peak State" />
      </Button>
    )
  }, [logoTextOnly, homeLink, width])

  const [navBar, { height }] = useSize(
    <Box role="navigation" className={clsx(className, classes.navBar)}>
      <Container className={classes.container}>
        <Box className={classes.left}>
          {previous && (
            // Back to category
            <Button
              color="inherit"
              component={RouterLink}
              to={previous.to}
              startIcon={<ArrowLeftIcon />}
            >
              {width !== 'xs' && previous.title}
            </Button>
          )}
        </Box>
        <Box className={classes.middle}>{!transparent && homeButton}</Box>
        <Box className={classes.right}>
          <LanguageSelector setContent={setContent} />
          <IconButton aria-label="menu" color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer
            transitionDuration={{ enter: 400, exit: 300 }}
            anchor={'right'}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              className={classes.drawer}
              role="navigation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <Box className={classes.drawerMain}>
                <Box className={classes.drawerLogo}>
                  <img
                    className={classes.drawerLogoImg}
                    width={125}
                    src={logoWithText}
                    alt={content.name}
                  />
                </Box>
                <Typography className={classes.drawerTitle} variant={titleVariant}>
                  {content.general.goal}
                </Typography>
                <List className={classes.drawerList}>
                  {contentStructure.categories.map((id) => (
                    <NavListItem
                      key={id}
                      to={`/${id}`}
                      title={content.categories[id].title}
                      withArrow
                    />
                  ))}
                </List>
              </Box>
              <List className={classes.drawerList}>
                <NavListItem to="/tools" title={content.categories['tools'].title} />
                <NavListItem to="/about-peak-state" title={content.aboutPeakState.title} />
                <NavListItem to="/about-mental-fitness" title={content.aboutMentalFitness.title} />
                <NavListItem to="/the-science" title={content.theScience.title} />
                <NavListItem to="/further-help" title={content.furtherHelp.title} />
              </List>
            </Box>
          </Drawer>
        </Box>
      </Container>
    </Box>,
  )

  return (
    <>
      <Box className={classes.root}>{navBar}</Box>
      {!transparent && <Box height={height < Infinity ? height : 0} />}
    </>
  )
}

export default NavBar
