import { useCallback, useState } from 'react'

const useOnScreen = <T extends Element>(threshold = 0) => {
  // start observing a node
  const setRef = useCallback(
    (node: T) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIsOnScreen(entry.isIntersecting)
        },
        {
          threshold,
        },
      )

      node && observer.observe(node)
      return () => {
        node && observer.unobserve(node)
      }
    },
    [threshold],
  )

  // State and setter for storing whether element is visible
  const [isOnScreen, setIsOnScreen] = useState(false)

  return { setRef, isOnScreen }
}

export default useOnScreen
