import React from 'react'

export const ContentContext = React.createContext(process.env.REACT_APP_REALM)

interface Props {
  children: (
    setContent: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => React.ReactNode
}

const ContentContextProvider: React.FC<Props> = (props) => {
  const { children } = props

  const [content, setContent] = React.useState(process.env.REACT_APP_REALM)

  return <ContentContext.Provider value={content}>{children(setContent)}</ContentContext.Provider>
}

export default ContentContextProvider
