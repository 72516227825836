
import { createElement, Fragment } from 'react'
import createSVGIcon from '@material-ui/icons/utils/createSvgIcon'

export default createSVGIcon(
    createElement(
    Fragment,
    null,
    createElement('g', {'clipPath': 'url(#clip0)'}),
createElement('path', {'d': 'M134.28 45.61L27.47 230.61H241.09L134.28 45.61ZM134.28 90.14L202.53 208.35H66L134.28 90.14Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.25', 'd': 'M241.09 230.62L172.83 112.39L159.98 134.65L202.53 208.35L241.09 230.62Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.25', 'd': 'M134.28 45.61L159.98 90.12L147.12 112.38L134.28 90.14V45.61Z'}),
createElement('path', {'d': 'M189.63 186H104.62L159.94 90.2L211.5 1L262.9 90.2L318.25 186H215.34L202.48 163.74H279.68L237.2 90.2L211.5 45.5L185.64 90.2L143.18 163.74H176.78L189.63 186Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.6', 'd': 'M279.68 163.74H202.48L215.34 186H318.25L279.68 163.74Z'}),
createElement('path', {'style': 'mix-blend-mode:multiply', 'opacity': '0.6', 'd': 'M143.18 163.74H176.78L189.63 186H104.62L143.18 163.74Z'}),
createElement('path', {'d': 'M211.5 45.53L237.2 90.2H262.9L211.5 1L159.94 90.2H185.64L211.5 45.53Z'}),
createElement('defs', {})
), 'LogoWithText')
    