import GroundingTechniques from './GroundingTechniques.png'
import EmotionalRegulationSystems from './EmotionalRegulationSystems.svg'
import Gratitude from './Gratitude.png'
import ProblemSolving from './ProblemSolving.png'
import GoalSetting from './GoalSetting.png'
import SocialImage from './SocialImage.png'
import ThePowerOfNature from './ThePowerOfNature.png'
import StrengthsIdentification from './StrengthsIdentification.png'
import VanessaGreen from './VanessaGreen.png'
import HomeBanner from './HomeBanner.png'
import BodyRelaxationTechniques from './BodyRelaxationTechniques.png'
import SelfCompassionAndAcceptance from './SelfCompassionAndAcceptance.png'
import Intro from './Intro.png'
import StandingTall from './StandingTall.png'
import BreathingTechniques from './BreathingTechniques.png'
import EverydayCreativity from './EverydayCreativity.png'
import Visualisation from './Visualisation.png'
import SelfAwarenessOfValues from './SelfAwarenessOfValues.png'
import EmotionalDiffusionTechniques from './EmotionalDiffusionTechniques.png'
import HeaderHome from './HeaderHome.png'
import StrengtheningOurSocialSupportNetwork from './StrengtheningOurSocialSupportNetwork.png'
import FifteenMinuteRule from './FifteenMinuteRule.png'
import PositiveSelfTalk from './PositiveSelfTalk.png'
import WhatIsMentalFitness from './WhatIsMentalFitness.png'
import NathanJones from './NathanJones.png'
import DistractionTechniques from './DistractionTechniques.png'
import IncreasingPositiveAttitude from './IncreasingPositiveAttitude.png'
import CognitiveModel from './CognitiveModel.svg'
import PeakState from './PeakState.svg'
import DavidWiseman from './DavidWiseman.png'
import PositiveSelfAffirmations from './PositiveSelfAffirmations.png'

export { default as GroundingTechniques } from './GroundingTechniques.png'
export { default as EmotionalRegulationSystems } from './EmotionalRegulationSystems.svg'
export { default as Gratitude } from './Gratitude.png'
export { default as ProblemSolving } from './ProblemSolving.png'
export { default as GoalSetting } from './GoalSetting.png'
export { default as SocialImage } from './SocialImage.png'
export { default as ThePowerOfNature } from './ThePowerOfNature.png'
export { default as StrengthsIdentification } from './StrengthsIdentification.png'
export { default as VanessaGreen } from './VanessaGreen.png'
export { default as HomeBanner } from './HomeBanner.png'
export { default as BodyRelaxationTechniques } from './BodyRelaxationTechniques.png'
export { default as SelfCompassionAndAcceptance } from './SelfCompassionAndAcceptance.png'
export { default as Intro } from './Intro.png'
export { default as StandingTall } from './StandingTall.png'
export { default as BreathingTechniques } from './BreathingTechniques.png'
export { default as EverydayCreativity } from './EverydayCreativity.png'
export { default as Visualisation } from './Visualisation.png'
export { default as SelfAwarenessOfValues } from './SelfAwarenessOfValues.png'
export { default as EmotionalDiffusionTechniques } from './EmotionalDiffusionTechniques.png'
export { default as HeaderHome } from './HeaderHome.png'
export { default as StrengtheningOurSocialSupportNetwork } from './StrengtheningOurSocialSupportNetwork.png'
export { default as FifteenMinuteRule } from './FifteenMinuteRule.png'
export { default as PositiveSelfTalk } from './PositiveSelfTalk.png'
export { default as WhatIsMentalFitness } from './WhatIsMentalFitness.png'
export { default as NathanJones } from './NathanJones.png'
export { default as DistractionTechniques } from './DistractionTechniques.png'
export { default as IncreasingPositiveAttitude } from './IncreasingPositiveAttitude.png'
export { default as CognitiveModel } from './CognitiveModel.svg'
export { default as PeakState } from './PeakState.svg'
export { default as DavidWiseman } from './DavidWiseman.png'
export { default as PositiveSelfAffirmations } from './PositiveSelfAffirmations.png'

export default {
  GroundingTechniques,
  EmotionalRegulationSystems,
  Gratitude,
  ProblemSolving,
  GoalSetting,
  SocialImage,
  ThePowerOfNature,
  StrengthsIdentification,
  VanessaGreen,
  HomeBanner,
  BodyRelaxationTechniques,
  SelfCompassionAndAcceptance,
  Intro,
  StandingTall,
  BreathingTechniques,
  EverydayCreativity,
  Visualisation,
  SelfAwarenessOfValues,
  EmotionalDiffusionTechniques,
  HeaderHome,
  StrengtheningOurSocialSupportNetwork,
  FifteenMinuteRule,
  PositiveSelfTalk,
  WhatIsMentalFitness,
  NathanJones,
  DistractionTechniques,
  IncreasingPositiveAttitude,
  CognitiveModel,
  PeakState,
  DavidWiseman,
  PositiveSelfAffirmations,
}
