import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

import Document from '../components/Document'

import { Content } from '../types'
import { ContentStructure } from '*/structure.yaml'

interface Props {
  contentStructure: ContentStructure
  content: Content
}

const Legal: React.FC<Props> = (props) => {
  const {
    content: { legals },
  } = props
  const { legalId } = useParams<{ legalId: string }>()
  const legal = legals[legalId]

  if (legal === undefined) {
    return <Redirect to="404" />
  }

  return <Document document={legal} />
}

export default Legal
