import React from 'react'
import clsx from 'clsx'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Link as RouterLink } from 'react-router-dom'
import { useHover } from 'react-use'

import useContent from '../hooks/useContent'

import Markdown from '../components/Markdown'

import { PeaksGraphic } from '../svg'
import { CategoryMarkdownCopy } from '*/copy.yaml'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      paddingTop: '100%',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
    },
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.secondary.main,
    },
    paper: {
      backgroundColor: fade(theme.palette.common.white, 0.75),
      padding: theme.spacing(1),
      color: theme.palette.common.black,
    },
    mountain: {
      position: 'absolute',
      bottom: -theme.spacing(2) - 1,
      left: -theme.spacing(2),
      right: -theme.spacing(2),
      width: `calc(100% + ${theme.spacing(4)}px)`,
      zIndex: 0,
    },
    flexItem: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      position: 'relative',
      zIndex: 1,
    },
    persistent: {
      marginTop: theme.spacing(1),
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
      }),
      justifyContent: 'center',
    },
    shrink: {
      marginTop: 0,
    },
    transient: {
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
      }),
      opacity: 1,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    disappear: {
      opacity: 0,
    },
  }),
)

const CategoryBox: React.FC<{ id: string; content: CategoryMarkdownCopy; achieve: string }> = (
  props,
) => {
  const { id, content, achieve } = props
  const { svg } = useContent()
  const classes = useStyles()

  const peaksGraphic = svg.PeaksGraphic || PeaksGraphic

  const element = (hovered: boolean) => (
    <Box className={classes.container}>
      <img className={classes.mountain} src={peaksGraphic} alt={content.title} />
      <Box
        className={clsx({
          [classes.flexItem]: true,
          [classes.persistent]: true,
          [classes.shrink]: hovered,
        })}
      >
        <Markdown content={content.title} variantMap={{ p: 'h4' }} />
      </Box>
      <Box
        className={clsx({
          [classes.flexItem]: true,
          [classes.transient]: true,
          [classes.disappear]: !hovered,
        })}
      >
        <Paper
          elevation={0}
          className={clsx({
            [classes.paper]: true,
          })}
        >
          <Markdown content={content.summary ?? ''} variantMap={{ p: 'body1' }} />
        </Paper>
      </Box>
      <Paper
        className={clsx({
          [classes.flexItem]: true,
          [classes.transient]: true,
          [classes.disappear]: !hovered,
          [classes.paper]: true,
        })}
      >
        <Markdown content={achieve} variantMap={{ p: 'h6' }} />
      </Paper>
    </Box>
  )
  const [hoverable] = useHover((a) => element(a))

  return (
    <ButtonBase focusRipple className={classes.root} to={`/${id}`} component={RouterLink}>
      {hoverable}
    </ButtonBase>
  )
}

export default CategoryBox
